import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout header={false} contentCss={tw`p-0`}>
    <div css={tw`text-center h-screen flex justify-center items-center`}>
      <Link
        to="/"
        css={tw`font-black text-brand text-5xl uppercase tracking-normal pr-8 border-grey-light border-r-2 hover:no-underline hover:text-black`}
      >
        404
      </Link>
      <p css={tw`pl-8`}>Uh oh! The route cannot be found. Simply press the back button.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
